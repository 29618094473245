<template>
  <div id="store">
    <TopBar class="background">
      <template v-slot:leftInfo>
        <svg
          @click="toLastIndex"
          t="1601170867949"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4264"
          width="24"
          height="24"
        >
          <path
            d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z"
            p-id="4265"
            fill="#ffffff"
          ></path>
        </svg>
      </template>
      <template v-slot:middleInfo>
        <span class="topTitle">师傅店铺</span>
      </template>
      <template v-slot:rightInfo> </template>
    </TopBar>
    <div class="userBackWrap">
      <!-- <p class="slogan">亿师傅为每个师傅打造专属品牌</p> -->
      <div class="userInfo">
        <div></div>
        <div class="userPic"></div>
        <p class="userName">冯师傅</p>
        <div class="usergGrade">
          <span>18721315266</span>
          <span>暂无等级</span>
          <span>一年店铺</span>
        </div>
      </div>
    </div>
    <LearnList
      :dataList="storeList"
      style="background: none; margin-top: -4rem"
    >
    </LearnList>
    <p class="dateTip">数据统计截止到昨日24:00，每日最迟07:00更新</p>
    <div class="dataWrap" v-for="(item, index) in baseDataList" :key="index">
      <div class="baseData">
        <div class="baseDataType">
          <p>{{ item.baseDataType }}</p>
        </div>
        <p class="evaluation">{{ item.evaluation }}</p>
        <ul class="dataContent">
          <li class="dataList" v-for="(k, i) in item.dataList" :key="i">
            <img
              :src="
                k.isHave > 0
                  ? require('@/assets/images/true.png')
                  : k.isHave < 0
                  ? require('@/assets/images/erroy.png')
                  : ''
              "
              alt=""
            />
            {{ k.content }}
          </li>
        </ul>
      </div>
    </div>
    <div style="margin-bottom: 30px"></div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import LearnList from "@/components/learn/LearnList.vue";

export default {
  components: {
    TopBar,
    LearnList,
  },
  data() {
    return {
      storeList: [
        {
          title: "综合评分",
          src: require("@/assets/images/learnList/2-1.png"),
          fraction: 5.0,
        },
        {
          title: "服务质量分",
          src: require("@/assets/images/learnList/2-2.png"),
          fraction: 100,
        },
        {
          title: "好评率",
          src: require("@/assets/images/learnList/2-3.png"),
          fraction: "80%",
        },
        {
          title: "服务次数",
          src: require("@/assets/images/learnList/2-4.png"),
          fraction: 152,
        },
      ],
      baseDataList: [
        {
          baseDataType: "大家都再说",
          evaluation: "大家还没有对你评价",
          dataList: [],
        },
        {
          baseDataType: "基本资料",
          evaluation: "",
          dataList: [
            {
              content: `服务类型： 家具（配送，安装，维修，保养）`,
              isHave: 0,
            },
            {
              content: `服务地区：上海市松江区，长宁区`,
              isHave: 0,
            },
            {
              content: `服务介绍：服务介绍:--`,
              isHave: 0,
            },
          ],
        },
        {
          baseDataType: "附加服务承诺",
          evaluation: "",
          dataList: [
            {
              content: `免费搬3层楼，单件100公斤以下，楼层超出每层：10元/件（25公斤以下不收费）`,
              isHave: 1,
            },
            {
              content: `无物流地址，超出30公里的距离收取5元/公里,`,
              isHave: 1,
            },
            {
              content: `提供免费发票`,
              isHave: -1,
            },
            {
              content: `提供增值税普通发票`,
              isHave: -1,
            },
          ],
        },
        {
          baseDataType: "基本服务承诺",
          evaluation: "",
          dataList: [
            {
              content: `提供增值税普通发票`,
              isHave: 1,
            },
            {
              content: `空跑费（安装、维修、保养30元/次）`,
              isHave: 1,
            },
            {
              content: `空跑费(送货、返货50元/次)`,
              isHave: 1,
            },
            {
              content: `安装/维修3个月免费检修1次`,
              isHave: 1,
            },
            {
              content: `二次上门费（提货安装80元/次)`,
              isHave: 1,
            },
            {
              content: `免费平移（适用送货到楼下、送货到家、送货到家并安装、返货）`,
              isHave: 1,
            },
            {
              content: `到货通知后2小时内完成预约`,
              isHave: 1,
            },
          ],
        },
      ],
    };
  },
  methods: {
    toLastIndex() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
#store {
  .userBackWrap {
    width: 100%;
    padding: 1rem 0 4rem 0;
    background: #ff816c url("../../../assets/1.png") no-repeat bottom / cover;
  }
  .userBackWrap {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .slogan {
      color: #fff9;
      margin: 1.5rem 0;
      font-size: 1.4rem;
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.4rem;
      .userPic {
        background: url("https://cdn.yishifu.com/image/20201120115341pOqumoQj.png") no-repeat center /
          cover;
        width: 7rem;
        height: 7rem;
        border-radius: 100%;
      }
      .userName {
        font-size: 1.8rem;
        color: #fff;
        margin: 0.5rem 0;
      }
      .usergGrade {
        display: flex;
        flex-wrap: nowrap;
        white-space: normal;
        margin: 1rem;
        span {
          display: inline-block;
          background: white;
          padding: 0.5rem 1rem;
          border-radius: 10rem;
          margin: 0.5rem;
          color: #797979;
          display: flex;
          justify-content: center;
          align-content: center;
        }
      }
    }
  }
  .dateTip {
    text-align: center;
    background: #fff;
    font-size: 1.4rem;
    color: #b6b6b6;
    padding: 1rem;
  }
  .dataWrap {
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 1rem;
    .baseData {
      .baseDataType {
        border-left: 5px solid #ff816c;
        font-size: 1.8rem;
        font-weight: 600;
        p {
          padding-left: 1.2rem;
        }
      }
      .evaluation {
        font-size: 1.4rem;
        color: #b6b6b6;
        padding: 1rem 2rem 0;
      }
      .dataContent {
        padding: 0.5rem 1rem 0;

        .dataList {
          font-size: 1.5rem;
          padding: 0.4rem 0;
          img {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
}
</style>
